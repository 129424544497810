<script>
  import { conf, state} from './stores.js'
  import { atobUnicode } from './h.js'
  import Start from './L_Start.svelte'
  export let context={}

  if(typeof window !=='undefined' && window.context) {
    context=JSON.parse(atobUnicode(window.context))
  }
  $state=context.state || {}
  $conf=context.conf || {}
  let online=false
	//$: $state.online=$state.wsConnected && online
  $: $state.online=online
</script>
<Start/>

<svelte:window bind:online/>
<svelte:head>
	<meta name="theme-color" content="{$conf.metaThemeColor || 'grey'}">
</svelte:head>