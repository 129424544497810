<script>
  import { conf, lang, wsc } from './stores.js'
  import { init, inArray, asArray, locale, spyScroll, i18n, fuc, openHref } from './h.js'
  import Switch from './G_Switch.svelte'
  import PricingGrid from './G_PricingGrid.svelte'

  import { onMount } from 'svelte'
  import { fly,fade,blur } from 'svelte/transition'

  let localConf={wsUrl:"wss://wss.eqrito.com"}
  init(localConf)
  if($wsc) $wsc.subscribe("front")

  let whitePaper="" //"https://cdn.eqrito.com/assets/Livre-blanc-eQrito-v2.4.pdf"
  let presWhite="eQrito_v2.7_white.pdf"

  let imgAnalytics = [
    "https://cdn.eqrito.com/assets/analytics1.png",
    "https://cdn.eqrito.com/assets/analytics2.png",
    "https://cdn.eqrito.com/assets/analytics3.png",
    "https://cdn.eqrito.com/assets/analytics4.png",
  ]

  let imgs = [
    {src: "https://api.eqrito.com/v4/qr/BP4GT/preview/png/400", label:"Bois-Colombes discovery tour"},
    {src: "https://api.eqrito.com/v4/qr/PRV78A/preview/png/400", label:"Bois-Colombes arboretum"},
    {src: "https://api.eqrito.com/v4/qr/Q6NYR/preview/png/400", label:"Agir pour le coeur des femmes"},
    {src: "https://api.eqrito.com/v4/qr/ATV92/preview/png/400", label:"A travers le vin"}
  ]
  
  let index=0
  function rotate(){
    if(index+3>=imgs.length) index=0
    else index++
  }

  setTimeout(() => {
    rotate()
    setInterval(() => {
      rotate()
    }, 1250)
  }, 600)



  setTimeout(() => {
    imgs=[...imgs, ...[
      {src: "https://api.eqrito.com/v4/qr/MLBEY/preview/png/400", label:"Little Beyrouth"},
      {src: "https://api.eqrito.com/v4/qr/N9JZX/preview/png/400", label:"Winch audit et conseil"},
      {src: "https://api.eqrito.com/v4/qr/WK3UB/preview/png/400", label:"Galerie en Ré"},
      {src: "https://api.eqrito.com/v4/qr/GDUJG/preview/png/400", label:"Sharing 360"},
      {src: "https://api.eqrito.com/v4/qr/GHR29/preview/png/400", label:"Passy AcroLac"},
      {src: "https://cdn.eqrito.com/assets/unity_coupon_transparent.png", label:"Coupon App"},
      //{src: "https://api.eqrito.com/v4/qr/EEU6C/preview/png/400", label:"graphiste colombes"},
      {src: "https://api.eqrito.com/v4/qr/H8VAQ/preview/png/400", label:"Dplex"},
      //{src: "https://cdn.eqrito.com/assets/yourqr.png", label:"yourqr"},
      {src: "https://api.eqrito.com/v4/qr/MPMB6/preview/png/400", label:"Gymnase de la cité de Lausanne"},
      //{src: "https://cdn.eqrito.com/assets/ugly_transparent.png", label:"Make beautiful QR Code"},
      {src: "https://api.eqrito.com/v4/qr/C7FCN/preview/png/400", label:"Club commerce Bois-Colombes"},
      //{src: "https://api.eqrito.com/v4/qr/XU9ZG/preview/png/400", label:"Italia in Bocca"},
      {src: "https://cdn.eqrito.com/assets/unity_survey_transparent.png", label:"Survey App"},
      {src: "https://api.eqrito.com/v4/qr/Q5DHF/preview/png/400", label:"Les Cinq Sens"},
      //{src: "https://api.eqrito.com/v4/qr/P5VZ5/preview/png/400", label:"La Reuz"},
      {src: "https://cdn.eqrito.com/assets/unity_lead_transparent.png", label:"Lead App"},
      {src: "https://api.eqrito.com/v4/qr/47XVM/preview/png/400", label:"Les Louloutins"},
      {src: "https://cdn.eqrito.com/assets/unity_game_transparent.png", label:"Game App"},
      {src: "https://api.eqrito.com/v4/qr/UA8MX/preview/png/400", label:"Creaprint"}, 
      //{src: "https://api.eqrito.com/v4/qr/DEHEZ/preview/png/400", label:"Captain Fox"},
      {src: "https://cdn.eqrito.com/assets/unity_app_transparent.png", label:"App store"},
      //{src: "https://api.eqrito.com/v4/qr/7W23R/preview/png/400", label:"Yamu"},
      {src: "https://api.eqrito.com/v4/qr/UH6ZW/preview/png/400", label:"Hoummos traiteur"},
      {src: "https://cdn.eqrito.com/assets/unity_arboretum_transparent.png", label:"Arboretum App"},
      {src: "https://api.eqrito.com/v4/qr/WQ553/preview/png/400", label:"Y&B"},
      {src: "https://cdn.eqrito.com/assets/unity_social_transparent.png", label:"Social App"},
      {src: "https://api.eqrito.com/v4/qr/TZKQP/preview/png/400", label:"Guyane Amazonie"},
      {src: "https://cdn.eqrito.com/assets/unity_media_transparent.png", label:"Medias App"},
      {src: "https://api.eqrito.com/v4/qr/ILHAIR/preview/png/400", label:"I love hair"},
      {src: "https://cdn.eqrito.com/assets/unity_info_transparent.png", label:"Info App"},
      {src: "https://api.eqrito.com/v4/qr/PLGED/preview/png/400", label:"PLG Edition"},
      {src: "https://cdn.eqrito.com/assets/unity_locator_transparent.png", label:"Locator App"},
      {src: "https://cdn.eqrito.com/assets/unity_tour_transparent.png", label:"Tour App"},
      {src: "https://cdn.eqrito.com/assets/unity_event_transparent.png", label:"Event App"},
      {src: "https://cdn.eqrito.com/assets/unity_link_transparent.png", label:"Link app"}
    ]]
  }, 1500)

  //console.log($conf)
  onMount(async () => {
    spyScroll()
	})

  function CTA(){
    //alert(i18n("home--opening"))
    openHref("https://app.eqrito.com/?action=try",{location:true})
  }


  let year=2021
  let menus=[
    {id: 'top', name: '<i class="fa-light fa-home">'},
    {id: 'unitys', name: 'home--les +'},
    {id: 'analytics', name: 'home--analytics'},
    {id: 'features', name: 'home--features'},
    {id: 'pricing', name: 'home--pricing'},
    //{id: 'parrain', name: 'home--sponsorship'},
    {id: 'news', name: 'home--newspapers'},
    {id: 'contact', name: 'home--contact'}
  ]

  //let slogan='home--Les QR codes qui boostent votre communication'
  //let slogan='home--Faites la différence avec des beaux QR codes'
  //let slogan='home--Les QR codes qui boostent le parcours client'
  //let slogan='home--Bien <i class="fa-solid fa-plus-circle"></i> que des QR codes'
  //let slogan='home--LE QR Code qui donne vie à vos idées'
  //let subSlogan='home--Engagez vos clients, réduisez vos coûts et soyez fiers de vos QR Codes'
  
  let tags=[
    //{name: 'Moins de papiers, moins de notices, éco-responsable et économique ', icon:'leaf', desc: ''},
    //{name: 'Du contenu relationnel et informationnel intégré', icon:'info-circle', desc: ''},
    //{name: 'Des intéractions, des promotions, des sondages, des invitations à des événements', icon:'handshake-alt', desc: ''},
    //{name: 'Une solution complète, un design attractif et personnalisé', icon:'spray-can'},
    //{name: 'Collecte, analyse, suivi des scans et des interactions', icon:'user-chart', desc: ''},
    //{name: 'Personnalisation très avancée', icon:'spray-can'},
    //{name: 'Suivi et statistiques des scans', icon:'analytics', desc: ''},
    //{name: 'De nombreuses fonctions intégrées', icon:'plus-circle', desc: ''},
    //{name: 'QR dynamiques et modifiables', icon:'spray-can'},
    //{name: 'Analyses complètes', icon:'user-chart'},
    //{name: 'home--Design attractif et personnalisé', icon:'spray-can'},
    //{name: 'home--Analyse des interactions clients', icon:'user-chart', desc: ''},
    //{name: 'home--Solution marketing complète', icon:'plus-circle'}
    {name: 'home--Tag 1', icon:'spray-can'},
    {name: 'home--Tag 2', icon:'user-chart', desc: ''},
    {name: 'home--Tag 3', icon:'plus-circle'}
  ]

  let unityHoverTimeout
  let unitys=[//will be take from config.json
    {id: 'social', name: 'unity--social', icon:'hashtag', desc: 'unity--social description'},
    {id: 'app', name: 'unity--app', icon:'mobile-alt', desc: "unity--app description"},
    {id: 'card', name: 'unity--card', icon:'address-card', desc: "unity--card description"},
    //{id: 'lead', name: 'unity--lead', icon:'user', desc: 'unity--lead description'},
    //{id: 'event', name: 'unity--event', icon:'calendar-check', desc: 'unity--event description'},
    //{id: 'game', name: 'unity--game', icon:'dice', desc: 'unity--game description'},
    //{id: 'locator', name: 'unity--locator', icon:'map-marker-alt', desc: 'unity--locator description'},
    //{id: 'arboretum', name: 'unity--arboretum', icon:'flower-daffodil', desc: 'unity--arboretum description'},
    {id: 'custom', name: 'unity--custom', icon:'shapes', desc: "unity--custom description"},
    {id: 'show', name: 'unity--show', icon:'presentation', desc: "unity--show description"},
    {id: 'info', name: 'unity--info', icon:'magnifying-glass', desc: "unity--info description"},
    //{id: 'media', name: 'unity--media', icon:'photo-video', desc: 'unity--media description'},
    //{id: 'coupon', name: 'unity--coupon', icon:'ticket-alt', desc: 'unity--coupon description'},
    //{id: 'survey', name: 'unity--survey', icon:'comment', desc: 'unity--survey description'},
    //{id: 'tour', name: 'unity--tour', icon:'map-marked-alt', desc: "unity--tour description"}
  ]
  unitys.sort(() => (Math.random() > .5) ? 1 : -1);
  unitys.unshift({id: 'link', name: 'unity--link', icon:'link', desc: 'unity--link description'})
  let unitySelected=unitys[0]

  let features=[
    {id: 'dynamic', name: 'home--Feature name dynamic', icon:'pen', desc: 'home--feature dynamic'},
    {id: 'design', name: 'home--Feature name design', icon:'spray-can', desc: 'home--feature design'},
    {id: 'i18n', name: 'home--Feature name i18n', icon:'globe', desc: 'home--feature i18n'},
    {id: 'tags', name: 'home--Feature name classification', icon:'tags', desc: 'home--feature tags'},
    {id: 'analytic', name: 'home--Feature name analytics', icon:'analytics', desc: 'home--analytics-visualize'},
    {id: 'unitys', name: 'home--Feature name unitys', icon:'puzzle-piece', desc: 'home--feature unitys'},
    {id: 'protection', name: 'home--Feature name protection', icon:'lock', desc: 'home--feature protection'},
    //{id: 'batch', name: 'home--Feature name batch', icon:'hat-wizard', desc: 'home--feature batch'},
    {id: 'hook', name: 'home--Feature name hooks', icon:'shapes', desc: 'home--feature hook'},
    {id: 'frame', name: 'home--Feature name frame', icon:'bullseye-pointer', desc: 'home--feature frame', roadmap:true},
    {id: 'api', name: 'API', icon:'plug', desc: 'home--feature api'}
  ]
</script>


<div id="spyroot" class="global">

  <nav id="navbar" class="fixed-top navbar navbar-expand-lg">
    <div class="container-fluid">
      <!-- Brand -->
      <a href="./#">
        <img class="navbarBrandImg" src="/img/v2020.1.logo.eqrito.full%20transparent-large.png" height=60 width=200 alt="eQrito" title="EQrito">
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
        <span class=""><i class="fa-light fa-bars"></i></span>
      </button>

      <!-- Navbar links -->
      <div class="collapse navbar-collapse" id="collapsibleNavbar">
        <ul class="navbar-nav nav">
          {#each menus as menu}
            <li class="ucfirst nav-item mx-lg-3">
              <a class="nav-link" on:click={()=>{window.location="./#"+ menu.id}} href="./#{menu.id}" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">{@html i18n(menu.name, $lang)}</a>
            </li>
          {/each}
        </ul>

        <ul class="navbar-nav nav ms-auto">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="./#" role="button" aria-expanded="false">
              <i class="ucfirst fa-light fa-globe fa-1x fa-fw"></i>{$conf.langNames[$lang]}
            </a>
            <ul class="dropdown-menu dropdown-menu-end">
              {#each $conf.langs as langId}
                <li><span class="ucfirst pointer dropdown-item" href="./fr#" on:click={locale(langId)}>{$conf.langNames[langId]}</span></li>
              {/each}
            </ul>
          </li>
        </ul>
        
        <ul class="navbar-nav ml-auto">
          <li class="nav-item nav-btn">
            <a class="btn btn-warning m-1 text-white text-nowrap"  
              href="https://app.eqrito.com" normal=true>
              {i18n("home--Sign in", $lang)}
            </a>
          </li>
        </ul>

      </div>
    </div>
  </nav>


  <main>
    <section id="top">
      <div class="container mt-5">
        <h1 class="display-2 lh-1 fw-bold">{i18n("home--slogan", $lang)}</h1>
        <h2 class="fs-3 lh-base subSlogan">{i18n("home--sub slogan", $lang)}</h2>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-lg-6">

            <div class="gallery" on:aamouseenter={rotate}>
              {#each imgs as img,i}
                <img 
                  src={img.src}
                  style="display:none;" 
                  height=1 width=1 alt="eQrito.com beautiful dynamic qrCode {img.label}" title="{img.label}" class="item" 
                  class:item-1={i==index}
                  class:item-2={i==index+1}
                  class:item-3={i==index+2}
                  class:noShadow={img.src.includes("_transparent")}
                  class:rotate={img.src.includes("unity_")}
                >
              {/each}
            </div>

          </div>


          <div class="col-lg-6 align-self-center d-flex justify-content-center">
            <div>
              {#each tags as tag}
                <h3 class="mb-4 fs-4"><i class="fa-solid fa-{tag.icon} fa-fw"></i> {@html i18n(tag.name, $lang)}</h3>
              {/each}
              
              <div class="text-center mt-5 cta fs-1" on:click={()=>CTA()}>{i18n("home--Free trial", $lang)} <i class="fa-solid fa-chevron-right"></i></div>
              <div class="text-center mt-1 small">{i18n("home--Free trial no card", $lang)}</div>
              
            </div>

          </div>
        </div>
        {#if whitePaper}
          <div class="text-center fs-4 mt-5 ">
            <a href={whitePaper} target="_blank"><i class="fa-regular fa-arrow-right"></i> Téléchargez gratuitement le livre blanc du QR Code efficace</a>
          </div>
        {/if}
      </div>
    
    </section>



    <section id="unitys">
      <div class="container">
        <div class="text-center">
          <div class="sectionTitle display-2">{@html i18n('home--Les +', $lang)}</div>
          <p class="subSectionTitle">{i18n("home--Unitys-tag 1", $lang)}</p>
          <p class="subSectionTitle">{i18n("home--Unitys-tag 2", $lang)}</p>
        </div>
        <div class="d-flex flex-wrap justify-content-around m-4">
          {#each unitys as unity}
            <div class="pointer text-center col-4 col-sm-2 px-4 py-2 mb-2 unity" class:active={unity.id==unitySelected.id}
              on:click|stopPropagation ={()=>unitySelected=unity}
              on:focus|stopPropagation ={()=>null}
              on:blur|stopPropagation ={()=>null}
              on:mouseover|stopPropagation ={()=>
                unityHoverTimeout = setTimeout(function(){
                  unitySelected=unity
                }, 180)
              }
              on:mouseout|stopPropagation ={()=>clearTimeout(unityHoverTimeout) }
              >
              <i class="circle fa-regular fa-{unity.icon} fa-fw"></i>
              <span class="title d-none d-lg-block fw-bold">{fuc(i18n(unity.name, $lang))}</span>
            </div>
          {/each}
        </div>

        {#key unitySelected}
          <div class="row">
            <div class="col-4">
                <img class="img-fluid" src="https://cdn.eqrito.com/assets/unity_{unitySelected.id}_transparent.png" alt="eQrito.com functionality {unitySelected.id}" title="unity {unitySelected.id}">
            </div>
            <div class="col-8">
              <div class="h1 fw-bold">{fuc(i18n(unitySelected.name, $lang))}</div>
              <p>{i18n(unitySelected.desc, $lang)}</p>
              <span class="cta fs-6" on:click={()=>CTA(unitySelected.id)}>{i18n("home--Créer un", $lang)} QR {fuc(i18n(unitySelected.name, $lang))} <i class="fa-solid fa-chevron-right"></i></span>
              <div class="mt-1 xsmall">{i18n("home--Free trial no card", $lang)}</div>
            </div>
          </div>
        {/key}
      </div>

    </section>



    <section id="analytics">
      <div class="container">
        <div class="text-center">
          <div class="sectionTitle display-2">{i18n("home--Statistiques", $lang)}</div>
          <p class="subSectionTitle">{i18n("home--Analytics-realtime", $lang)}</p>
          <p class="subSectionTitle">{i18n("home--Analytics-visualize", $lang)}</p>
        </div>
        <div class="sectionContent">
          {#each imgAnalytics as img,i}
            <img class="shadow-lg img-fluid my-4" src={img} alt="eQrito.com analytics" title="analytics">
          {/each}
        </div>
        <span class="cta fs-2" on:click={()=>CTA()}>{i18n("home--Free trial", $lang)} <i class="fa-solid fa-chevron-right"></i></span>
        <div class="mt-1 xsmall">{i18n("home--Free trial no card", $lang)}</div>
      </div>
    </section>


    

    <section id="features">
      <div class="container ">
        <div class="text-center">
          <div class="sectionTitle display-2">{i18n("home--Features", $lang)}</div>
          <p class="subSectionTitle">{i18n("home--Features-includes", $lang)}</p>
        </div>
        <div class="sectionContent row">
          <div class="col-lg mb-5 mr-5">
            <div class="mt-5 row row-cols-1 row-cols-md-2">
              {#each features as feature}
                <div class="col">
                  <div class="row">
                    <div class="ms-4 g-0 col-auto fw-bold"><i class="circle fa-solid fa-{feature.icon} fa-fw"></i></div>
                    <div class="col">
                      <span class="fw-bold">{i18n(feature.name, $lang)} {#if feature.roadmap}({i18n("home--on roadmap", $lang)}){/if}</span>
                      <p style="text-align: justify;">{i18n(feature.desc, $lang)}</p>
                    </div>
                  </div>
                </div>
              {/each}
            </div>
            <span class="cta fs-2" on:click={()=>CTA()}>{i18n("home--Free trial", $lang)} <i class="fa-solid fa-chevron-right"></i></span>
            <div class="mt-1 xsmall">{i18n("home--Free trial no card", $lang)}</div>
          </div>
        </div>

        <div class="text-center">
          <div class="sectionTitle display-2">{i18n("home--Functions", $lang)}</div>
          <p class="subSectionTitle">{i18n("home--Features-functions", $lang)}</p>
        </div>
        <div id="unitys2" class="sectionContent row">
          <div class="col-lg mb-5 mr-5">
            <div class="mt-5 row row-cols-1 row-cols-md-2">
              {#each unitys as unity}
                <div class="col">
                  <div class="row">
                    <div class="ms-4 g-0 col-auto fw-bold"><i class="circle fa-solid fa-{unity.icon} fa-fw "></i></div>
                    <div class="col">
                      <span class="fw-bold">{fuc(i18n(unity.name, $lang))}</span>
                      <p style="text-align: justify;">{i18n(unity.desc, $lang)}</p>
                    </div>
                  </div>
                </div>
              {/each}
            </div>
            <span class="cta fs-2" on:click={()=>CTA()}>{i18n("home--Free trial", $lang)} <i class="fa-solid fa-chevron-right"></i></span>
            <div class="mt-1 xsmall">{i18n("home--Free trial no card", $lang)}</div>
          </div>
        </div>
      </div>
    </section>
    
    <section id="pricing">
      <div class="container ">
        <div class="text-center">
          <div class="sectionTitle display-2">{i18n("home--Pricing", $lang)}</div>
          <p class="subSectionTitle">{i18n("home--Pricing-clear", $lang)}</p>
          <p class="subSectionTitle">{i18n("home--pricing-agency", $lang)}</p>
        </div>
        
      <div class="sectionContent">
        <PricingGrid pricing={$conf.pricing} {CTA}/>
      </div> 

        <div class="mt-3 text-center h4">
          <a target="_blank" href="https://eqrito.com/{presWhite}"><i class="fa-solid fa-file-pdf"></i> {i18n("home--Présentation eQrito PDF", $lang)}</a>
        </div>

      </div>
    </section>





    <!--section id="parrain">
      <div class="container">
        <div class="text-center">
          <div class="sectionTitle display-2">{i18n("home--Sponsorship", $lang)}</div>
        </div>
        <div class="sectionContent">
          <div class="mt-3"><i class="circle fa-solid fa-gift fa-fw"></i> {i18n("home--sponsorship tag 1", $lang)}</div>
          <div class="mt-3"><i class="circle fa-solid fa-plus fa-fw"></i> {i18n("home--sponsorship tag 2", $lang)}</div>

          <div class="mt-3"><i class="circle fa-solid fa-percent fa-fw"></i> {i18n("home--sponsorship tag 3", $lang)}</div>
          
          <div class="row">
            <div class="col-lg mb-5 mr-5">
              <div class="mt-5">
                <span class="cta fs-2" on:click={()=>CTA()}>{i18n("home--Free trial", $lang)} <i class="fa-solid fa-chevron-right"></i></span>
                <div class="mt-1 xsmall">{i18n("home--Free trial no card", $lang)}</div>
              </div>
            </div>
  
  
            <div class="col-lg">
              
          </div>
        </div>
      </div>
    </section-->

    <section id="news">
      <div class="container">
        <div class="text-center">
          <div class="sectionTitle display-2">{i18n("home--Speak about us", $lang)}</div>
        </div>
        <div class="sectionContent">
        
          <div class="mt-3">
            <a target="_blank" rel="noopener" href="https://www.leparisien.fr/hauts-de-seine-92/pour-ses-125-ans-bois-colombes-remonte-le-temps-avec-un-parcours-numerique-a-travers-la-ville-15-05-2021-XMHYIPIY3NAHLO3G2BPFHSVLUQ.php">Le Parisien 92 - Mai 2021</a>
          </div>

          <div class="mt-3">
            <a target="_blank" rel="noopener" href="https://www.lesechos.fr/pme-regions/ile-de-france/hauts-de-seine-bois-colombes-celebre-son-histoire-avec-un-parcours-numerique-1318172#:~:text=La%20ville%20de%20Bois%2DColombes,install%C3%A9s%20dans%20les%20lieux%20embl%C3%A9matiques.&text=Pour%20c%C3%A9l%C3%A9brer%20les%20125%20ans,a%20voulu%20marquer%20le%20coup.">Les Echos - Mai 2021</a>
          </div>
          
          <div class="mt-3">
            <a target="_blank" rel="noopener" href="https://www.amd92.fr/attachments/article/3724/CP_circuit-numerique-bois-colombes_avril2021.pdf">Communiqué de presse Bois-Colombes - Circuit Numérique</a>
          </div>

          <!--div class="mt-3">
            <a target="_blank" rel="noopener" href="https://eqrito.com/eqrito_pres_v1.pdf">Présentation eQrito & eQrito Tour PDF</a>
          </div-->
        </div>
      </div>
    </section>

    <section id="contact">
      <div class="container">
        <div class="text-center">
          <div class="sectionTitle display-2">{i18n("home--Contact", $lang)}</div>
          <p class="subSectionTitle">{i18n("home--Question project", $lang)}</p>
          <p class="subSectionTitle">{i18n("home--Contact us", $lang)}</p>

        </div>
        <div class="text-center sectionContent">
          <img class="navbarBrandImg" src="/img/v2020.1.logo.eqrito.full%20transparent-large.png" height=60 width=200 alt="eQrito contact" title="EQrito">
          <address><a href="tel:+33(0)634229139">+33 (0) 6 34 22 91 39</a></address>
          <address><a href="mailto:hello@eQrito.com?subject=Just%20a%20question">hello@eQrito.com</a></address>
        </div>
      </div>
    </section>
  </main>
  
  <br><br>
  
  <footer class="fixed-bottom">
    <div class="container text-center">
        <span>
          <i class="fa-solid fa-copyright" aria-hidden="true"></i> {year} - eQrito.com 
          - <a href="/cgu" >CGU</a>
          - <a href="/cgv">CGV</a>
          - <span style="font-size: 0.6rem;"><a target="blank" href="https://www.denso-wave.com/qr2019/en">QR Code is a registered trademark of DENSO WAVE</a></span>
        </span>
    </div>
  </footer>

</div>

<style>
  @font-face {
      font-family: Gilroy;
      font-weight: bold;
      src: url("/front/fonts/Gilroy-ExtraBold.otf") format("opentype");
  }
  @font-face {
      font-family: Gilroy;
      src: url("/front/fonts/Gilroy-Light.otf") format("opentype");
  }

  asection:not(#top)::before { /* hack to scroll just under navbar*/
    content: '';
    display: block;
    height: 76px;
    margin-top: -76px;
  }

  .unity{
    border-bottom:10px solid white;
  }
  .unity:hover, .unity.active{
    border-bottom:10px solid var(--brandColor2);
  }

  .gallery {
    align-items: center;
    display: flex;
    height: 20rem;
    position: relative;
  }

  .gallery .item {
    display:initial!important;
    height: 1rem;
    width: 1rem;
    opacity: 0;
    position: absolute;
    transition: all 0.3s ease-in-out;
    z-index: 0;
    left: 50%;
    transform: translateX(-50%);
  }


  .gallery .item-1,
  .gallery .item-3 {
    height: 10rem;
    width: 10rem;
    opacity: 1;
    z-index: 1;
  }

  .gallery .item-1 {
    left: 28%;
    transform: translateX(-50%);
  }

  .gallery .item-2 {
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
    height: 15rem;
    width: 15rem;
    opacity: 1;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
  }

  .rotate{
    transform:  translateX(-50%) rotate(5deg)!important;
  }

  .noShadow{
    box-shadow:none!important;
  }

  .gallery .item-3 {
    left: 72%;
    transform: translateX(-50%);
  }



  .global {
    position: relative; 
    --brandColor:#ffcc00;/* orange */
    --sectionColorBg:rgba(0,0,0,0.04);/* orange opacity 0.15*/
    --brandColor2:#483737;/* brun */
    --textOnbrandColor:#5a5a5a;
    font-family: Gilroy,Roboto,Arial,sans-serif;
    font-size: 1.2rem;
  }

  .navbar-collapse { /* menu in middle */
    flex-grow: 0.5;
  }
  .navbarBrandImg {height: 60px; width:auto;}
  .navbar {background-color: white;}
  .nav-link {color: var(--brandColor2);font-weight: bold;}
  .nav-link:hover {color:var(--brandColor);}
  :global(.nav-link.active) {color:var(--brandColor)!important;}


  .cta{
    background-color:var(--brandColor2);
    color:var(--brandColor);
    font-weight: bold;
    padding: 5px 15px;
    cursor: pointer;
  }

  .sectionTitle {
    color:var(--brandColor2);
    padding-bottom:2rem;
  }

  
  .subSectionTitle {
    font-size: 1.5rem;
    line-height: 1.5;
    color:var(--brandColor2);
  }

  .sectionContent {
    padding-top:2rem;
  }

  section:nth-child(2n+1) {background:var(--sectionColorBg);}

  section{
    padding-top:76px;
    padding-bottom:2rem;
  }


  .brandTextColor{color:var(--brandColor);}
  .navbar-toggler{color:var(--brandColor);}
  .navbar-toggler:hover{color:var(--brandColor);}

 

  #top{background-color:var(--brandColor);color:var(--brandColor2);}
  .subSlogan{color:var(--textOnbrandColor);}
   
  footer, footer * {background-color: var(--brandColor2);color:white;font-size: 0.8rem;}

  .circle{
    color:white;
    border-radius: 50%;
    border: 0px;
    display: inline-block;
    line-height: 1.8em;
    text-align: center;
    width: 1.8em; 
    font-size: 1.3em;
  }
  #unitys .circle{
    background-color:var(--brandColor);
  }
  #unitys .title{
    color:var(--brandColor2);
  }

  #features .circle{
    background-color:var(--brandColor);
  }
  #unitys2 .circle{
    background-color:var(--brandColor2);
  }

  #parrain .circle{
    background-color:var(--brandColor);
  }



  .important {
    color: lime;
    font-weight: bold;
    font-size: 1.2em;
  }
  .btn-choose {
    color:var(--brandColor2);
		background-color: var(--brandColor);
    border-color: var(--brandColor);
  }
  .btn-choose:hover {
    opacity:0.5;
  }

  :global(.form-check-input:checked) {
    background-color: var(--brandColor)!important;
    border-color: var(--brandColor)!important;
  }
</style>