import { writable, get } from 'svelte/store'
import _merge from 'lodash/merge'

export let conf = writable({}) // conf read form config.json and remote getAppSettings 
export let state = writable({}) // state of app (show, hide, auth....) //TOREMOVE

export let gModal = writable({})
export let gAlert = writable({})
export let gSpinner = writable(false)
export let activityId = writable(false)

export let route = writable("/")
export let routeRoot = writable("/")
export let routeMatched = writable(false)
export let routeReload = writable(false)

export let storage = writable({}) // localStorage
export let wsc = writable(false) // wss client

export let wCustomer = writable({}) // current working customer //TOREMOVE
export let w = writable({}) // window properties (height, width, xorigin)

/************************************ */
export let userId = writable(false) // current userId
export let user = writable({}) // current user //TOREMOVE
export let permissions = writable({}) // current permissions //TOREMOVE
export let customer = writable({}) // current customer //TOREMOVE
export let customers = writable([]) // customers //TOREMOVE

export const uuid = writable(doLocalStorage('uuid') || uuidV4())
uuid.subscribe((value) => doLocalStorage('uuid', value))

export const lang = writable(doLocalStorage('lang') || '')
lang.subscribe((value) => doLocalStorage('lang', value))

export const customerId = writable(doLocalStorage('customerId') || null)
customerId.subscribe((value) => doLocalStorage('customerId', value))

export const token = writable(doLocalStorage('token') || null)
token.subscribe((value) => doLocalStorage('token', value))

function doLocalStorage(id, value){
  let prefix=''
  if(typeof localStorage !=='undefined' ){
    if(value!=null) localStorage[prefix+id] = JSON.stringify(value)
    else return JSON.parse(localStorage.getItem(prefix+id))
  }
}

/*if (typeof window !== 'undefined') window.addEventListener("storage", localStorageSetHandler, false)
function localStorageSetHandler(e){
  console.log(e)
}*/

function uuidV4(noDash) {
  let f='xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
  if(noDash) f='xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'
  return f.replace(/[xy]/g, function(c) {
  let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8)
  return v.toString(16)
  })
}

// --------------------------------------------
// ----- external stores access (-;)
// --------------------------------------------
let stores={
  route:route,
  routeRoot:routeRoot,
  routeMatched:routeMatched,
  routeReload:routeReload,
  lang:lang,
  uuid:uuid,
  conf:conf,
  state:state,
  storage:storage,
  wsc:wsc,
  token:token,
  user:user,
  userId:userId,
  permissions:permissions,
  customer:customer,
  customers:customers,
  customerId:customerId
}
export function _s(storeName, obj, replace=false) {
  if(!stores[storeName]) return
  let store=stores[storeName]
  if(obj!=null) {
    if(replace) store.set(obj)
    else store.update(cur => {
      if(typeof obj !== "object" || typeof cur !== "object") return obj
      return _merge(cur, obj)
    })
    return get(store)
  } else return get(store)
}